import {
  IAppService,
  IStateConfig,
  IFxpAppContext,
  IServiceEndPoints,
  IRouteInfo,
  IPartnerBundle,
  IAppServiceWithSharedComponent,
  ISharedComponents,
  ISharedComponentConfig,
  ComponentFramework,
} from '@fxp/fxpservices';
import { appSettings } from './environments/common/appsettingsGenerator';
import CESMonthViewComponent from './app/components/CESMonthView/CesMonthView';

// Root application class. Please rename this class as per the name of your application.
export class RMAvailabilityReactApp
  implements IAppService, IAppServiceWithSharedComponent
{
  getSharedComponentsInfo(): ISharedComponents {
    const MonthlyCalendarSharedComp: ISharedComponentConfig = {
      component: CESMonthViewComponent,
      componentDescription:
        'This component renders react availability calendar.',
      componentInputs: [
        ['dateMonth', 'month to view'],
        ['eventViewType', "One of 'Assignment', 'Outlook'"],
      ],
      componentOutputs: [
        ['onSelectEvent', 'generic event that determines selected event'],
      ],
      exposeAsExternalComponents: false,
      componentFramework: ComponentFramework.React,
      componentName: 'rmavailabilityreactapp-mycalendar',
    };

    return {
      appName: 'rmavailabilityreactapp',
      components: [MonthlyCalendarSharedComp],
      disableSharing: false,
      sharedBundles: ['rmavailabilityreactapp-bundle'],
    };
  }
  getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
    let isLowerEnv = false;
    if (
      ['dev', 'sit'].includes(
        appSettings().flightingAppEnvironment.toLocaleLowerCase()
      )
    ) {
      isLowerEnv = true;
    }

    const CESMonthlyCalendar: IStateConfig = {
      name: 'mycalendar',
      url: '/my-calendar',
      component: CESMonthViewComponent,
      componentFramework: 'React',
      data: {
        headerName: 'My Calendar',
        breadcrumbText: 'My Calendar Component',
        pageTitle: 'My Calendar Page',
      },
    };

    const routeInfo: IRouteInfo = {
      applicationName: 'rmavailabilityreactapp',
      sharedBundles: ['rmavailabilityreactapp-bundle'],
      routes: [CESMonthlyCalendar],
    };

    // Add test component in lower environments
    if (isLowerEnv) {
      routeInfo.routes.push(CESMonthlyCalendar);
    }

    return routeInfo;
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }

  getBundles(): IPartnerBundle[] {
    const baseUrl = appSettings().cdnBaseUrl;
    const bundle: IPartnerBundle = {
      name: 'rmavailabilityreactapp-bundle',
      files: [
        `${baseUrl}/vendor.bundle.js`,
        `${baseUrl}/styles.bundle.js`,
        `${baseUrl}/main.bundle.js`,
      ],
      sequentialLoading: true,
    };

    return [bundle];
  }
}
