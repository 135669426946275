import { RAvailUrlProvider } from '../../common/RAvailUrlProvider';
import { FXPServiceCatalog, FxpServiceFactory } from '@fxp/fxpservices-di';
import { FxpHttpService, FxpMessageService } from '@fxp/fxpservices';
import {
  CalendarData,
  CalendarEvent,
  CEEventDetails,
  CEForecastSummaryDto,
  CEFreeFlexResponse,
  CERecommendedHrsResponse,
  FreeHours,
  ReactBigCalEvent,
} from '../../common/Contracts';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';

interface ICalendarService {
  getRefreshEventsObs: () => Observable<void>;
  didModifyEvents: (val: any | null) => void;
  manualUpdateForecastHours: (val: any, eventCategory: 'talentforecast' | string) => void;
  fetchTalentRecommendedHours: (
    alias: string,
    startDate: Date,
    endDate: Date
  ) => Promise<CERecommendedHrsResponse>;
  fetchTalentForecast: (
    alias: string,
    startDate: Date,
    endDate: Date
  ) => Promise<CEFreeFlexResponse>;
  fetchCalendarData: (startDate: Date, endDate: Date) => Promise<CalendarData>;
  parseCalendarResponse: (resp: CalendarEvent[]) => ReactBigCalEvent[];
  parseAssignments: (resp: CEEventDetails[]) => ReactBigCalEvent[];
  mergeForecasts: (
    availableHours: FreeHours[],
    talentForecast: CEFreeFlexResponse,
    reqStartDate: Date,
    reqEndDate: Date
  ) => ReactBigCalEvent[];
  postFreeFlexHours: (
    alias: string,
    startDate: string,
    freeHours: number,
    flexHours: number
  ) => Promise<boolean>;
}

const CalendarService = (function () {
  let _instance: ICalendarService;
  // Constructor
  function createInstance(): ICalendarService {
    const today = new Date(Date.now());
    let refreshEventsSubject = new Subject<any>();

    //#region Services
    const urlProvider = new RAvailUrlProvider();
    const httpService = FxpServiceFactory.getServiceInstance(
      FXPServiceCatalog.FxpHttpService
    ) as FxpHttpService;
    const messageService = FxpServiceFactory.getServiceInstance(
      FXPServiceCatalog.FxpMessageService
    ) as FxpMessageService;
    //#endregion

    const getRefreshEventsObs = () => {
      return refreshEventsSubject.asObservable();
    };

    const didModifyEvents = (val: any | null) => {
      refreshEventsSubject.next(val);
    };

    const manualUpdateForecastHours = (updateObj: any, eventCategory: 'talentforecast' | string) => {
      didModifyEvents({updateObj,eventCategory});
    };

    const fetchTalentRecommendedHours = async (
      alias: string,
      startDate: Date,
      endDate: Date
    ): Promise<CERecommendedHrsResponse> => {
      try {
        const url = urlProvider.GetRecommenedForecastUrl();
        const payload = {
          alias,
          startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
          endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
          includeOutlookEvents: true,
          includeProjectEvents: true,
          includeNonProjectEvents: true,
          includeHolidayEvents: true,
          includeEventsInResponse: true,
        };
        const response = await httpService.post(url, payload, {
          'Content-Type': 'application/json;',
        });
        console.log('API response:', response);
        console.log('Recommended hours:', response.data.availableHours);
        console.log(response);

        let ret = response.data as CERecommendedHrsResponse;
        return ret;
      } catch (error) {
        if (!!error.status && (error.status < 200 || error.status > 299)) {
          messageService.addMessage(
            'Failed to fetch assignments and recommended hours',
            'error'
          );
          return {
            resourceAlias: alias,
            availableHours: [],
            eventDetails: [],
          };
          throw new Error(
            !!error.data ? error.data : 'Failed to fetch recommended hours'
          );
        }
      }
    };

    const fetchTalentForecast = async (
      alias: string,
      startDate: Date,
      endDate: Date
    ): Promise<CEFreeFlexResponse> => {
      try {
        const forecastApiUrl = urlProvider.GetTalentForecastUrl();

        const payload = {
          resourceAlias: alias,
          startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
          endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
          includeFlexHours: true,
          includeFreeHours: true,
        };

        const forecastResponse = await httpService.post(
          forecastApiUrl,
          payload,
          {
            'Content-Type': 'application/json;',
          }
        );

        if (
          !!forecastResponse.status &&
          (forecastResponse.status < 200 || forecastResponse.status > 299)
        ) {
          messageService.addMessage('Failed to fetch user forecast', 'error');
          return [];
        }

        let ret = forecastResponse.data;

        if (ret && ret.length) {
          return ret;
        } else {
          return [];
        }
        return forecastResponse.data as CEFreeFlexResponse;
      } catch (error) {
        if (!!error.status && (error.status < 200 || error.status > 299)) {
          messageService.addMessage('Failed to fetch user forecast', 'error');
          return [];
        }
        return null;
      }
    };

    const fetchCalendarData = async (
      startDate: Date,
      endDate: Date
    ): Promise<CalendarData> => {
      try {
        const calendarApiUrl = urlProvider.GetCalendarEventsUrl(
          startDate,
          endDate
        );

        const calendarResponse = await httpService.post(calendarApiUrl, null);

        return calendarResponse.data as CalendarData;
      } catch (error) {
        if (!!error.status && (error.status < 200 || error.status > 299)) {
          messageService.addMessage('Failed to fetch Outlook Events', 'error');
          return {
            calendarEvents: [],
            workPreferences: {
              workingDays: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
              ],
              startTime: '08:00:00',
              endTime: '17:00:00',
            } as any,
          };
        }
        return null;
      }
    };

    const fetchCalendarSettings = async () => {};

    //#region Helper methods
    const mergeForecasts = (
      availableHours: FreeHours[],
      talentForecast: CEFreeFlexResponse,
      reqStartDate: Date,
      reqEndDate: Date
    ): ReactBigCalEvent[] => {
      availableHours = !!availableHours ? availableHours : [];

      let recHours = availableHours.map(recHr => ({
        durationInHours: recHr.durationInHours,
        date: moment(recHr.date).hour(0).minute(0).second(0).millisecond(0),
      }));

      let flexHoursMap = new Map<string, number | undefined>();
      talentForecast
        .filter(x => x.docType === 'cesflexforecast')
        .forEach(x => {
          let date = moment(x.forecastDate)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0);
          let flexHours =
            x.forecast && x.forecast.user && x.forecast.user.hours != undefined
              ? x.forecast.user.hours
              : undefined;
          flexHoursMap.set(moment(date).format('YYYY-MM-DD'), flexHours);
        });
      let freeHoursMap = new Map<string, number | undefined>();
      talentForecast
        .filter(x => x.docType === 'cesfreeforecast')
        .forEach(x => {
          let date = moment(x.forecastDate)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0);
          let freeHours =
            x.forecast && x.forecast.user && x.forecast.user.hours != undefined
              ? x.forecast.user.hours
              : undefined;
          freeHoursMap.set(moment(date).format('YYYY-MM-DD'), freeHours);
        });

      let freeFlexHours = [];
      flexHoursMap.forEach((val, key) => {
        // corresponding free hours
        let freeHours: number;
        let flexHours = val;
        if (freeHoursMap.has(key)) {
          freeHours = freeHoursMap.get(key);
          freeHoursMap.delete(key);
        }

        freeFlexHours.push({
          date: moment(key),
          freeHours,
          flexHours,
        });
      });

      freeHoursMap.forEach((val, key) => {
        freeFlexHours.push({
          date: moment(key),
          freeHours: val,
          flexHours: undefined,
        });
      });

      let dateItr = moment(reqStartDate)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      let endDate = moment(reqEndDate)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      for (
        let date = moment(reqStartDate)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0);
        date <= endDate;
        date = date.add(1, 'd')
      ) {
        // check if free/flex hours are present or not
        let index = freeFlexHours.findIndex(x => x.date.isSame(date, 'd'));
        if (index < 0)
          // add free/flex hour object
          freeFlexHours.push({
            date: moment(date),
            freeHours: undefined,
            flexHours: undefined,
          });
      }

      let forecastArr: CEForecastSummaryDto[] = [];
      while (dateItr <= endDate) {
        let recIdx = recHours.findIndex(x => x.date.isSame(dateItr, 'd'));
        let ffIdx = freeFlexHours.findIndex(x => x.date.isSame(dateItr, 'd'));

        let forecastObj = {
          date: dateItr.toISOString(true).replace(/[\+\-][0-9]+\:[0-9]+/i, ''),
        } as CEForecastSummaryDto;

        if (recIdx > -1) {
          forecastObj.freeHours = recHours[recIdx].durationInHours;
        }

        if (ffIdx > -1) {
          forecastObj.freeHours =
            freeFlexHours[ffIdx].freeHours != undefined
              ? freeFlexHours[ffIdx].freeHours
              : forecastObj.freeHours;
          forecastObj.flexHours = freeFlexHours[ffIdx].flexHours;
          forecastObj.isUserUpdated =
            freeFlexHours[ffIdx].freeHours != undefined ||
            freeFlexHours[ffIdx].flexHours != undefined;
        }

        forecastArr.push(forecastObj);
        dateItr = dateItr.add(1, 'd');
      }

      let ret: ReactBigCalEvent[] = forecastArr.map(dto => ({
        title: `Free ${dto.freeHours || 0} hrs | Flex ${
          dto.flexHours || 0
        } hrs`,
        start: dto.date,
        end: dto.date,
        tooltip: `Free - ${dto.freeHours || 0} hrs and Flex - ${
          dto.flexHours || 0
        } hrs`,
        customProperties: {
          eventCategory: 'talentforecast',
          freeHours: dto.freeHours,
          flexHours: dto.flexHours,
          isUserUpdated: dto.isUserUpdated,
        },
      }));

      return ret;
    };

    const parseCalendarResponse = (
      calendarEvents: CalendarEvent[]
    ): ReactBigCalEvent[] => {
      const arr: ReactBigCalEvent[] = [];
      calendarEvents.forEach((obj: CalendarEvent) => {
        let startTimeStr = moment(obj.startDateTime).format(
          'YYYY-MM-DD HH:mm:ss'
        );
        let endTimeStr = moment(obj.endDateTime).format('YYYY-MM-DD HH:mm:ss');

        arr.push({
          title: obj.subject,
          start: obj.startDateTime,
          end: obj.endDateTime,
          tooltip: `${obj.status} event | ${obj.subject} | ${startTimeStr} to ${endTimeStr}`,
          customProperties: {
            eventCategory: 'outlook',
            status: obj.status,
          },
        });
      });
      return arr;
    };

    const parseAssignments = (
      calendarEvents: CEEventDetails[]
    ): ReactBigCalEvent[] => {
      // Merge adjacent events if they take up > 8 hours

      let mergedMonthEvents = mergeAdjacentSchedules(calendarEvents);

      let eventDetails = mergedMonthEvents.map(ceEvent => {
        let tzStrippedStartDate = ceEvent.startDateTime.replace('Z', '');
        let tzStrippedEndDate = ceEvent.endDateTime.replace('Z', '');
        let startTimeStr = moment(tzStrippedStartDate).format(
          'YYYY-MM-DD HH:mm:ss'
        );
        let endTimeStr = moment(tzStrippedEndDate).format(
          'YYYY-MM-DD HH:mm:ss'
        );

        let eventCategory = ceEvent.eventCategory.toLowerCase();
        let tooltip = '';
        switch (eventCategory) {
          case 'nonproject':
            tooltip += `${ceEvent.customProperties.Id} | ${ceEvent.eventName} | ${startTimeStr} to ${endTimeStr}`;
            break;

          case 'project':
            tooltip += `${ceEvent.customProperties.ResourceRequestId} | ${ceEvent.customProperties.RequestType} | ${startTimeStr} to ${endTimeStr} | Requested Duration - ${ceEvent.customProperties.RequestedDurationInHours}`;
            break;
          default:
            break;
        }

        let gridEvent: ReactBigCalEvent = {
          start: tzStrippedStartDate,
          end: tzStrippedEndDate,
          title: ceEvent.eventName,
          tooltip,
          customProperties: {
            ...ceEvent.customProperties,
            eventCategory,
          },
        };
        return gridEvent;
      });

      return eventDetails;
    };

    const postFreeFlexHours = async (
      alias: string,
      startDate: string,
      freeHours: number,
      flexHours: number
    ): Promise<boolean> => {
      try {
        if (freeHours + flexHours > 8) {
          messageService.addMessage(
            'Validation error: Total hours on a day cannot exceed 8',
            'error'
          );
          return false;
        }
        const upsertUrl = urlProvider.PostFreeFlexForecastUrl();
        const dateStr = startDate;
        const upsertPayload = {
          StartDate: dateStr,
          EndDate: dateStr,
          ResourceAlias: alias,
          UpdatedBy: 'user',
          FreeSchedules: [
            {
              Hours: freeHours,
              Date: dateStr,
              UpdatedBy: 'user',
            },
          ],
          FlexSchedules: [
            {
              Hours: flexHours,
              Date: dateStr,
              UpdatedBy: 'user',
            },
          ],
        };

        const calendarResponse = await httpService.post(
          upsertUrl,
          upsertPayload,
          {
            'Content-Type': 'application/json;',
          }
        );

        return calendarResponse.data as boolean;
      } catch (error) {
        if (!!error.status && (error.status < 200 || error.status > 299)) {
          messageService.addMessage('Failed to update forecast', 'error');
          return false;
        }
        return false;
      }
    };
    //#endregion

    //#region Private methods
    const mergeAdjacentSchedules = (
      events: CEEventDetails[]
    ): CEEventDetails[] => {
      // store request ids with list of schedule dates
      const idsWithSchedules: Map<string, moment.Moment[][]> = new Map();

      events.forEach(elem => {
        let eventCategory = elem.eventCategory.toLowerCase();
        let idKey = '';

        switch (eventCategory) {
          case 'nonproject':
            idKey = `nonproj-${elem.customProperties.Id}`;
            break;

          case 'project':
            idKey = `proj-${elem.customProperties.ResourceRequestId}`;
            break;
          default:
            break;
        }

        if (!idKey.length) {
          console.log('Event ID not found!');
          return;
        }
        let scheduleStart = moment(elem.startDateTime.replace('Z', ''));
        let scheduleEnd = moment(elem.endDateTime.replace('Z', ''));

        if (!idsWithSchedules.has(idKey)) {
          idsWithSchedules.set(idKey, [[scheduleStart, scheduleEnd]]);
        } else {
          let curSchedules = idsWithSchedules.get(idKey);
          idsWithSchedules.set(idKey, [
            ...curSchedules,
            [scheduleStart, scheduleEnd],
          ]);
        }
      });

      let retEvents: CEEventDetails[] = [...events];

      idsWithSchedules.forEach((val, key) => {
        if (val.length < 2) return;

        let schedArr = val.sort((a, b) => a[0].diff(b[0]));
        let [eventCategory, id] = key.split('-');

        switch (eventCategory) {
          case 'nonproj':
            mergeProximalSchedules(schedArr);

            for (let index = 0; index < retEvents.length; index++) {
              const event = retEvents[index];

              if (`${event.customProperties.Id}` != id) continue;

              let start = moment(event.startDateTime.replace('Z', ''));
              let foundRange = schedArr.find(x => x[0].isSame(start, 'day'));

              if (foundRange) {
                event.endDateTime = foundRange[1].format(
                  'YYYY-MM-DDTHH:mm:ss.000'
                );
              } else {
                retEvents.splice(index--, 1);
              }
            }
            break;
          case 'proj':
            break;
        }
      });

      return retEvents;
    };

    const mergeProximalSchedules = (schedArr: moment.Moment[][]) => {
      for (let i = schedArr.length - 1; i > 0; i--) {
        let diff = schedArr[i][0].diff(schedArr[i - 1][0], 'day', true);
        if (diff <= 1) {
          schedArr[i - 1][1] = schedArr[i][1];
          schedArr.splice(i, 1);
        }
      }
    };
    //#endregion

    return {
      getRefreshEventsObs,
      didModifyEvents,
      manualUpdateForecastHours,
      fetchTalentRecommendedHours,
      fetchTalentForecast,
      fetchCalendarData,
      parseCalendarResponse,
      mergeForecasts,
      parseAssignments,
      postFreeFlexHours,
    };
  }

  return {
    getSingleton: function () {
      if (!_instance) {
        _instance = createInstance();
      }
      return _instance;
    },
    getNewInstance: function () {
      return createInstance();
    },
  };
})();

export default CalendarService;
