import { Constants } from './constants';
import { appSettings } from '../environments/common/appsettingsGenerator';
import moment from 'moment';

export class RAvailUrlProvider {
  environmentConfig = appSettings();

  public AvailabilityBaseUrl = () => this.environmentConfig.AvailabilityBaseUrl;

  public GetTalentForecastUrl = () =>
    `${this.AvailabilityBaseUrl()}${
      Constants.AvailabilityConstants.getTalentForecastUrl
    }`;

  public GetRecommenedForecastUrl = () =>
    `${this.AvailabilityBaseUrl()}${
      Constants.AvailabilityConstants.getRecommendedAvlUrl
    }`;

  public GetCalendarEventsUrl = (startDate: Date, endDate: Date) =>
    `${this.AvailabilityBaseUrl()}${
      Constants.AvailabilityConstants.getCalendarEventsUrl
    }?startDate=${moment(startDate)
      .format()
      .replace(/[\+\-][0-9]+\:[0-9]+/i, '')}&endDate=${moment(endDate)
      .format()
      .replace(/[\+\-][0-9]+\:[0-9]+/i, '')}`;

  public GetCalendarSettingsUrl = () =>
    `${this.AvailabilityBaseUrl()}${
      Constants.AvailabilityConstants.getCalendarSettingsUrl
    }`;

  public PostFreeFlexForecastUrl = () =>
    `${this.AvailabilityBaseUrl()}${
      Constants.AvailabilityConstants.updateTalentForecastUrl
    }`;
}
