export interface CERecommendedHrsResponse {
  resourceAlias: string;
  availableHours: FreeHours[];
  eventDetails?: CEEventDetails[];
}

export interface FreeHours {
  date: string;
  durationInHours: number;
}

export interface CEEventDetails {
  eventName: string;
  eventCategory: 'project' | 'msvacation' | 'nonproject' | 'publicholiday';
  startDateTime: string;
  endDateTime: string;
  customProperties?: Record<string, any>;
}

export interface CalendarData {
  calendarEvents: CalendarEvent[];
  workPreferences: WorkPreferences;
}

export interface CalendarEvent {
  subject: string;
  startDateTime: string;
  endDateTime: string;
  status: string;
  isPrivate: boolean;
}
export interface ReactBigCalEvent {
  title: string;
  start: string;
  end: string;
  tooltip: string;
  customProperties?: Record<string, any>;
}

export interface WorkPreferences {
  workingDays: string[];
  startTime: string;
  endTime: string;
  timezone: string;
}

export type CEFreeFlexResponse = CEFreeFlexItem[];

export interface CEFreeFlexItem {
  id: string;
  resourceAlias: string;
  forecastDate: string;
  docType: string;
  forecast: CEForecast;
}

export interface CEForecastSummaryDto {
  date: string;
  freeHours: number;
  flexHours: number;
  isUserUpdated?: boolean;
}
export interface CEForecast {
  user: CEUserForecast;
  computed: CEComputedForecast;
}

export interface CEUserForecast {
  hours: number;
  updatedDate: string;
}

export interface CEComputedForecast {
  hours: number;
  updatedDate: string;
}

export interface MyCalendarProps {
  monthDate: Date;
  onSelectEvent: Function;
}

export enum CECalendarEventViewType {
  OUTLOOK,
  ASSIGNMENTS,
}
