export const Constants = {
  BlockedEventTypes: [1, 14, 30, 35],
  UrlConstants: {
    basicProfileUrl: '#/profile/{alias}/basic',
    resourceCalendarUrl: '#/resourcedashboard/{alias}/ResourceManager',
    mailUrlForAlias: 'mailto:{alias}@microsoft.com',
    mailConfirmationUrlForMCSAlias: '/api/UserAvailabilityConfirmation',
    teamsUrlForAlias:
      'https://teams.microsoft.com/l/chat/0/0?users={alias}@microsoft.com',
    projectDetailsUrl: '#/project/{projectIdentifier}/false/resourcemanager',
    requestDetailsUrl:
      '#/project/{projectIdentifier}/resource/{requestIdentifier}/false/{userRole}',
  },
  OneProfileConstants: {
    searchProfileUrl: '/api/v1/profile/searchprofile?searchText=',
    locationUrl: '/api/v1/master/country',
    stdTitleUrl: '/api/v1/master/businessroles',
    generateReporteesUrl:
      '/api/v1/profile/{0}/directreportees/profileskillassessment',
    generateBasicProfileUrl: '/api/v2.0/profile/{0}?IncludeInactive=false',
    generatePrimarySkillForAliasUrl: '/api/v1/profile/{0}/primaryskill',
  },
  TimeApiConstants: {
    bulkHoursEndpoint: '/api/v2/assignments/labor',
    submitterEndpoint: '/api/v1/labor/submitter/{userAlias}',
    assignmentEndpoint: '/api/v1/assignments/{userAlias}',
  },
  GrmApiConstants: {
    getDomainDataEndpoint: '/api/v1/Domain/GetDomainData',
    filtersPayload: {
      DomainDataLists: ['GeographyData'],
    },
    eventTypesPayload: {
      DomainDataLists: ['EventTypes'],
    },
  },
  AvailabilityConstants: {
    getTalentForecastUrl: '/api/Talent/GetForecast',
    getRecommendedAvlUrl: '/api/Recommendation/GetRecommendedAvailability',
    getCalendarEventsUrl: '/api/Insights/GetMyCalendarEvents',
    getCalendarSettingsUrl: '/api/Insights/GetMyCalendarPermissions',
    updateTalentForecastUrl: '/api/Talent/UpdateForecast',
  },
  FlightingConstants: {
    ApplicationName: 'Resource Time Away',
    FXPApplicationName: 'Fxp',
    FXPFeatures: ['Talentwbflag'],
    Features: [
      'RMExperienceEnabled',
      'GeographyFilterEnabled',
      'RMResourceForecast',
      'RMResourceForecastExcluded',
      'RMResourceForecastBusinessRole',
      'ResourceTimeAwayCategories',
      'ResourceTimeAwayCountries',
      'Talentwbflag',
      'PJMToggleSupport',
      'HasAccessToAvailabilityForecastPage',
      'IsNewExperienceExclusiveCesRingUser',
      'IsCESCalendarGA',
      'CESCalendarEnabledForRoleId',
    ],
    CalendarFeatures: [
      'RMResourceForecast',
      'RMResourceForecastExcluded',
      'RMResourceForecastBusinessRole',
    ],
    FlightingParamsMapping: {
      Alias: 'alias',
      CountryCode: 'countryCode',
      ResourceCategory: 'resourceCategory',
      SDODomainId: 'SDODomainId',
      BusinessRoleId: 'businessRoleId',
    },
  },
  ProjectCategoryConstants: {
    SAPForecast: 'SAPForecast',
    VirtuosoForecast: 'VirtuosoForecast',
    Project: 'Project',
    NonProject: 'NonProject',
    PublicHoliday: 'PublicHoliday',
  },
  ExcelExportConstants: {
    EXCEL_TYPE:
      'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8',
    EXCEL_EXTENSION: '.xlsx',
    excelfilename: 'snapshot',
  },
  GermanCouncilUser: 'GermanCouncilUser',
  OutLookCalendarView: 'https://outlook.office.com/mail/options/calendar/view',
  ServiceName: 'ResourceAvailability',
  ResourceForecastAppName: 'ResourceAvailability.ResourceForecast',
  ResourceAssignmentsAppName: 'ResourceAvailability.ResourceAssignments',
  ResourceForecastSharedComponentName: 'ResourceAvailability.AFluxScheduler',
  PowerBIAppName: 'PowerBISharedComponent',
};

export enum UserRole {
  RM = 'ResourceManager',
  FM = 'FunctionalManager',
  REQUESTOR = 'Requestor',
}

export class ResourceForecastFeatures {
  static startDateChangeCalendar = `${Constants.ResourceForecastAppName}.StartDateChangeCalendar`;
  static startDateChangeChevron = `${Constants.ResourceForecastAppName}.StartDateChangeChevron`;
  static PJMForecastToggle = `${Constants.ResourceForecastAppName}.PJMForecastToggle`;
  static routeToRequestDetails = `${Constants.ResourceForecastAppName}.RouteToRequestDetails`;
  static updateForecast = `${Constants.ResourceForecastAppName}.UpdateForecast`;
  static listAssignments = `${Constants.ResourceForecastAppName}.ListAssignments`;
  static viewLabor = `${Constants.ResourceForecastAppName}.ViewLabor`;
}

export class ResourceForecastSharedComponentFeatures {
  static startDateChangeCalendar = `${Constants.ResourceForecastSharedComponentName}.StartDateChangeCalendar`;
  static startDateChangeChevron = `${Constants.ResourceForecastSharedComponentName}.StartDateChangeChevron`;
  static listAssignments = `${Constants.ResourceForecastSharedComponentName}.ListAssignments`;
  static viewLabor = `${Constants.ResourceForecastSharedComponentName}.ViewLabor`;
}

export enum CSAComponentNumberOfWeeks {
  FOUR = 4,
  SIX = 6,
  EIGHT = 8,
}

export interface CSAComponentConfig {
  numberOfWeeks?: CSAComponentNumberOfWeeks;
}
