
import { Reducer } from 'redux';
import { IAction } from '@fxp/statemanagement';
import { AppActionTypes } from './action.type';

export const ProfileReducer: Reducer<IProfileState, IAction<any>> = (
    state: IProfileState = { DisplayName: '' },
    action: IAction<any>
): IProfileState => {
    if (action.type === AppActionTypes.UPDATE_PROFILE) {
        return {
            DisplayName: action.payload.DisplayName
        };
    }
    return state;
};

export interface IProfileState {
    DisplayName: string;
}
