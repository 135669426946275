import { AppActionTypes } from './action.type';
import { ProfileReducer, IProfileState } from './profile.reducer';
import { Reducer, combineReducers, Store } from 'redux';
import { FxpGlobalStoreService } from '@fxp/fxpservices';
import { customLoggerMiddleware } from './logger.middleware';
import { FXPServiceCatalog, FxpServiceFactory } from '@fxp/fxpservices-di';
import { IAction } from '@fxp/statemanagement';
import { RAvailStore } from './store.model';

let appStore: Store<RAvailStore, IAction<any>> = null;
let globalStore: FxpGlobalStoreService = null;

export const AppReducer: Reducer = combineReducers({
  profile: ProfileReducer,
});

export const GetStore = () => appStore;
export const GetGlobalStore = () => globalStore;

export const RegisterStoreWithFxp = (appName: string) => {
  globalStore = (
    FxpServiceFactory.getServiceInstance(FXPServiceCatalog.FxpGlobalStoreService)
  ) as FxpGlobalStoreService;
  // appStore = globalStore.CreateStore(appName, AppReducer, [
  //   customLoggerMiddleware,
  // ]);
  // globalStore.RegisterGlobalActions(appName, [AppActionTypes.UPDATE_PROFILE]);
};
