import { PartnerAppRegistrationService } from '@fxp/fxpservices';
import { RMAvailabilityReactApp } from './application';
import { RegisterStoreWithFxp } from './app/store/app.store';

// Register your root application here.
const appName = 'rmavailabilityreactapp';
PartnerAppRegistrationService.registerPartnerApp(
  appName,
  RMAvailabilityReactApp
);
RegisterStoreWithFxp(appName);
